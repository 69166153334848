<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Dados Pessoais</template>
        <template #content>
          <p>
            Nome Completo: <b>{{ matriculas[0].nome }}</b>
          </p>
          <p>
            CPF: <b>{{ matriculas[0].cpf | mascaraCpf }}</b>
          </p>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Matrículas</template>
        <template #content>
          <DataTable
            stripedRows
            :value="matriculas"
            :loading="loading"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} matriculas"
            responsiveLayout="stack">
            <template #empty> Nenhuma matrícula encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column field="matricula" header="Matrícula"></Column>
            <Column field="entidadeNome" header="Entidade"></Column>
            <Column field="vinculoNome" header="Vínculo"></Column>
            <Column header="Situação">
              <template #body="{ data }">
                <Tag
                  :value="data.validacao.value"
                  :severity="data.validacao.severity"
                  :icon="data.validacao.icon"></Tag>
              </template>
            </Column>
            <Column header="Ações">
              <template #body="slotProps">
                <Button
                  v-if="matriculas"
                  v-tooltip.left="'Detalhes'"
                  class="p-button-rounded p-button-success mr-2"
                  @click="detalheServidor(slotProps.data)">
                  <span v-if="!loadingDetail" class="pi pi-eye"></span>
                  <span
                    v-if="loadingDetail"
                    class="pi pi-spin pi-spinner"></span>
                </Button>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService.js'

export default {
  data() {
    return {
      matriculas: [],
      matricula: {},
      deleteServidorDialog: false,
      loading: false,
      totalRecords: 0,
      lazyParams: {},
      loadingDetail: false,
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
  },

  mounted() {
    this.loading = true
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.loading = true
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.matriculas = res
          this.limpar()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
          this.$router.push('/logout')
        })
    },

    limpar() {
      this.loading = false
    },

    detalheServidor(data) {
      this.loadingDetail = true
      this.$router.push({ name: 'margem', params: { id: data.id } })
      this.loadingDetail = false
    },

    detalhesContracheque(data) {
      this.loadingDetail = true
      this.$router.push({
        name: 'contrachequeList',
        params: { matricula: data.matricula, cpf: data.cpf },
      })
      this.loadingDetail = false
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
